import React from 'react';
import './Footer.css';
import { Container, StackPanel, StackItem } from 'precise-ui';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import {config} from '../../config';

const Footer = (props) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 479px)'
    });
    return  (
        <div className="Footer">
            <div className="gray-bar-footer">
                <div className="page-container">
                    <Container>
                        <StackPanel direction={isMobile ? 'top' : 'left'} className='footer-column-same-size'>
                            <StackItem>
                                <h4 className="footer-title m-b-20">EXPLORE</h4>
                                <ul className="footer-list">
                                    <li><NavLink to="e-shop/returns" exact > E-commerce sales conditions </NavLink></li>
                                    <li><NavLink to="/standard-sales-conditions" exact > Standard sales conditions </NavLink></li>
                                    <li><NavLink to="code-of-conduct" exact > Code of Conduct </NavLink></li>
                                    <li><NavLink to="/product-manual" exact > Product Manual </NavLink></li>
                                    <li><NavLink to="/chemical-product-regulatory-compliance">Chemical and Product Regulatory Compliance</NavLink></li>
                                    <li><a href="https://www.zeiss.com/data-protection/en/home.html" target="_blank"> Privacy </a> </li>
                                </ul>
                            </StackItem>
                            <StackItem>
                                <h4 className="footer-title  m-b-20">ABOUT ZEISS SUNLENS </h4>
                                <ul className="footer-list">
                                    <li><NavLink to="/about-us" exact > About </NavLink></li>
                                    <li><NavLink to="/contact-us" exact > Contact Us </NavLink></li>
                                    <li><a href="https://www.zeiss.it/vision-care/sunlens.html" target="_blank">Website</a></li>
                                </ul>
                            </StackItem>
                            <StackItem>
                                <h4 className="footer-title m-b-20">SOCIAL MEDIA</h4>
                                <ul className="footer-list">
                                    <li><a href="https://www.linkedin.com/showcase/14633390/" target="_blank">LinkedIn </a></li>
                                    <li><a href="https://www.instagram.com/zeisssunlens/" target="_blank">Instagram </a></li>
                                    <li><a href="https://www.facebook.com/ZEISSsunlens/" target="_blank">Facebook </a></li>
                                    <li><a href="https://www.youtube.com/channel/UCg6EA1H_rJ0JbxNMBapixTg" target="_blank">YouTube </a></li>
                                </ul>
                            </StackItem>
                        </StackPanel>
                    </Container>
                </div>
            </div>

            <div className="black-bar-footer">
                <div className="page-container">
                    <div className="footer-utils">
                        <p className="footer-copyright"> © 2020 Vision Sunlens </p>
                        <p className="footer-links"> <NavLink to="/about-us" exact > About Us </NavLink> |  <a href="https://www.zeiss.com/data-protection/en/home.html" target="_blank"> Privacy </a> |
                        <NavLink to="/cookie-policy" exact > Cookies </NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;