import React, { Component } from "react";
import './EShop.css';
import axios from '../../../axios';
import Cookies from 'universal-cookie';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductCard from "./ProductCard/ProductCard";
import ModalProductQuantity from "../../../components/ModalProductQuantity/ModalProductQuantity";
import * as actionCreators from '../../../store/actions/index';
import { Icon, notify, Sidebar, PaddedContainer, Button, ModalBody, Modal, ModalHeader, Slider} from "precise-ui/dist/es6";
import ReactGA from 'react-ga';
import check from   '../../../res/images/check.png';
import cartIcon from '../../../res/images/cart.png';

const { Skeleton} = require('precise-ui');

class EShop extends Component {

    timeout = null;
    sliderFilterTimer = null;
    state = {
        sections : [],
        items: [],
        filters : [],
        activeFilters: [],
        showHomePage: true,
        hiddenFilters: [],
        openModalQuantity: false,
        cartProduct: null,
        showFiltersItemSkeleton: true,
        isScrolling: false,
        seeMoreState: [],
        openShopClosedModal: false,
        shopClosedEndDate: ''
    }

    sortSelection = 0;


    componentDidMount () {
        if(this.props.isPageVisible) {

            if (!this.loadFromSessionStorage()) {
                this.getHomePageEShop();
            }
            sessionStorage.removeItem('eshop');
            window.addEventListener("scroll", this.onScroll);
            window.scrollTo(0, 0);

            this.getUserCart();

            this.isEShopOpened();
        }
        else {
            this.props.history.push("/home");
        }
    }

    getUserCart = () => {
        if (this.props.authUser && this.props.authUser.zeissId) {
            axios.get('/getUserCart/'+this.props.authUser.zeissId+'/'+this.props.authUser.priceList)
            .then(response => {
                if (response.data.type === "success") {
                    this.props.updateCart(response.data.payload);
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    getHomePageEShop() {
        let params = {
            onlyPromoShop: this.props.onlyPromoShop,
            priceListCode:  this.props.authUser.priceList
        }

        axios.post('/getFiltersEShop', JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {

            let filters = response.data.Filters;
            let activeFilters = this.getActiveFilters(filters);

            this.setState(() => ({
                filters: filters,
                activeFilters: activeFilters,
                hiddenFilters: activeFilters.filter(f => f.category !== 'Lens Color').map(f => f.category),
                showHomePage: true
            }))

        })
        .catch(err => {
            console.log(err);
        });

        axios.post('/getEShopHome', JSON.stringify(params),)
        .then(response => {

            let seeMoreState = [];
            response.data.forEach(element => {
                seeMoreState.push({'id': element.ID, 'count': 8});
            });

            this.setState(() => ({
                ...this.state,
                sections: response.data,
                showFiltersItemSkeleton: false,
                seeMoreState: seeMoreState
            }))
        })
        .catch(err => {
            console.log(err);
        });
    }

    isEShopOpened() {

        let params = {
            section: 'ESHOP'
        }

        axios.post('/isModuleClosed', JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {
            if(response.data) {
                if(response.data.payload.isClosed){
                    this.setState({openShopClosedModal: true, shopClosedEndDate: response.data.payload.dateEnd});
                }

            }
        })
        .catch(err => {
            console.log(err);
        });

    }

    componentWillUnmount() {
        sessionStorage.setItem('eshop', JSON.stringify(this.state));
        window.removeEventListener("scroll", this.onScroll);
        if (this.sliderFilterTimer) {
            clearTimeout(this.sliderFilterTimer)
        }
    }

    onScroll = () => {
        this.setState({ isScrolling: true });
    };

    loadFromSessionStorage = () => {
        const routeState = this.props.location.state;
        if (routeState != null && (routeState.from.indexOf('/product') !== -1 || routeState.from.indexOf('/cart') !== -1)) {
            const prevState = sessionStorage.getItem('eshop');
            if (prevState) {
                this.setState(JSON.parse(prevState));
                return true;
            } else return false;
        } else {
            return false;
        }
    }

    getActiveFilters(filters) {
        let arr = [];
        filters.forEach(element => {
            let filterCatObj = { category: element.FilterCategory, values: [], sortSelection: 999};
            arr.push(filterCatObj);
        });
        return arr;
    }

    handleChangeFilter(filterCategory, filterId, filterValue) {


        this.sortSelection = this.sortSelection + 1;
        let fCategory = this.state.activeFilters.filter(e => e.category === filterCategory)[0];
        if (fCategory.category === 'VLT %') {
            if (fCategory.sortSelection === 999 && Array.isArray(fCategory.values) && fCategory.values.length > 0) {
                fCategory.sortSelection = this.sortSelection;
            }
            else { /* non deve fare niente */ }
        }
        else if (fCategory.category === 'Driving Suitability') {
            fCategory.sortSelection = this.sortSelection;
            let fValue = fCategory.values.filter(e => e.value === filterId);
            if (fValue.length > 0) {
                let index = fCategory.values.indexOf(fValue[0]);
                fCategory.values.splice(index, 1);
                if (filterId !== 'ISO_ANSI_ASNZS') {
                    let allStandardSelectedIndex = fCategory.values.findIndex(e => e.value === 'ISO_ANSI_ASNZS');
                    if (allStandardSelectedIndex !== -1) {
                        fCategory.values.splice(allStandardSelectedIndex, 1);
                    }
                }
            }
            else {
                fCategory.values.push({code: filterValue, value: filterId});
                if (filterId === 'ISO_ANSI_ASNZS') {
                    let drivingSuitabilityFilter = this.state.filters.find(f => f.FilterCategory === 'Driving Suitability');
                    if (!!drivingSuitabilityFilter && drivingSuitabilityFilter.Values.length > 0) {
                        let notSelectedSuitability = drivingSuitabilityFilter.Values.filter(v => !fCategory.values.find(fv => fv.value === v.ID) && v.ID !== filterId);
                        notSelectedSuitability.forEach(filterToAdd => fCategory.values.push({code: filterToAdd.Value, value: filterToAdd.ID}));
                    }
                }
            }
        }
        else if( fCategory.sortSelection == 999 ) {
            fCategory.sortSelection = this.sortSelection;
            fCategory.values.push({code: filterValue, value: filterId});
        }
        else if(fCategory.category == 'Coating Type' ||fCategory.category == 'Coating Color') {
            let fValue = fCategory.values.filter(e => e.value === filterId);
            if(fValue.length > 0) {
                let index = fCategory.values.indexOf(fValue[0]);
                fCategory.values.splice(index, 1);
            }
            else {
                fCategory.values.push({code: filterValue, value: filterId});
            }
        }
        else {
            fCategory.sortSelection = 999;
            fCategory.values.pop();
        }

        this.getFiltersAndItems();
    }

    getActiveFiltersCount() {
        let activeFiltersCount = 0;
        this.state.activeFilters.forEach(element => {
            if(element.values.length > 0 ){
                activeFiltersCount++;
            }
        });

        return activeFiltersCount;
    }

    getFiltersAndItems() {

        let activeFiltersCount = this.getActiveFiltersCount();

        this.setState(() => ({
            showFiltersItemSkeleton: true
        }))

         if(activeFiltersCount == 0 ) {
             this.getHomePageEShop();
         }
         else {

            let activeFilters = {
                filters: this.state.activeFilters,
                onlyPromoShop: this.props.onlyPromoShop,
                priceListCode:  this.props.authUser.priceList
            }

            axios.post('/getFiltersAndProductsEShop', JSON.stringify(activeFilters),{
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {

                let filters = response.data.Filters;
                let activeFilters = this.state.activeFilters;

                this.setState(() => ({
                    ...this.state,
                    items: response.data.Items,
                    filters: filters,
                    activeFilters: activeFilters,
                    showHomePage: false,
                    showFiltersItemSkeleton: false
                }))

                window.scrollTo(0, 0);
            })
            .catch(err => {
                console.log(err);
            });
         }
     }

    checkedFilter(item, filtrValue){
        let filterValues = this.state.activeFilters.filter(e => e.category === item.FilterCategory)[0];
        if(filterValues.values.length > 0 ) {
            return filterValues.values.find((f) => f.value === filtrValue)
        }
        return false;
    }

    productSelectedHandler = product => {

        //ANALYTICS
        /*ReactGA.plugin.require('ec', { debug: true });

        ReactGA.plugin.execute('ec', 'addImpression', {
            id: product.ID
        });

        ReactGA.plugin.execute('ec', 'addProduct', {
            id: product.ID
        });

        ReactGA.plugin.execute('ec', 'setAction', 'detail');

        ReactGA.pageview("/product/"+product.ID); */

        this.props.history.push("/product/"+product.ID, { from: this.props.location.pathname });

        /*
        ReactGA.ga('send', 'event', 'UX', 'click', 'productView',{
            hitCallback: () => {

            }
        });*/

    }

    changeSliderHandler = (filterCategory, event) => {
        let {value, index} = event;
        let activeFilterIndex = this.state.activeFilters.findIndex(f => f.category === filterCategory);
        let activeFilters = [...this.state.activeFilters];

        if (activeFilterIndex > -1) {
            if(!Array.isArray(activeFilters[activeFilterIndex].values)) {
                activeFilters[activeFilterIndex].values = [];
            }

            if (index === 0) {
                if (!activeFilters[activeFilterIndex].values[1]) {
                    activeFilters[activeFilterIndex].values[1] = {
                        code: 100,
                        value: 1
                    };
                }
            } else if (index === 1) {
                if (!activeFilters[activeFilterIndex].values[0]) {
                    activeFilters[activeFilterIndex].values[0] = {
                        code: 0,
                        value: 0
                    };
                }
            }

            if (!activeFilters[activeFilterIndex].values[index]) {
                activeFilters[activeFilterIndex].values[index] = {
                    code: 0,
                    value: 0
                };
            }

            value = parseFloat((Math.round(value*100)/100).toFixed(2));

            activeFilters[activeFilterIndex].values[index].code = value;
            activeFilters[activeFilterIndex].values[index].value = value/100;
            this.setState({
                ...this.state,
                activeFilters
            });
        }

        if (this.sliderFilterTimer) {
            clearTimeout(this.sliderFilterTimer);
        }
        this.sliderFilterTimer = setTimeout(() => this.handleChangeFilter(filterCategory, index, value), 1200);
    }

    renderFilters(item) {
        if ( item.FilterCategory === 'Lens Color') {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                item.Values.map((filterItem) => (
                    <div className="lens-color-container tooltip-on-hover" >
                        <div className="filter-item filter-item-color " key={filterItem.ID} style={{ backgroundColor: filterItem.Value }} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Extra)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }
                        </div>
                        <div className="tooltip">{filterItem.Extra} </div>
                    </div>
                ))
            }
            </div>
        }
        else if( item.FilterCategory === 'Coating Color') {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="lens-color-container tooltip-on-hover" >
                            <div className="filter-item f-item-coating-color" key={filterItem.ID}
                                style={{ background: "linear-gradient(90deg, " + filterItem.Extra.PrimaryHexCode + " 25%, " + filterItem.Extra.SecondaryHexCode + " 50%, " + filterItem.Extra.PrimaryHexCode + " 75%"}} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }
                            </div>
                            <div className="tooltip">{filterItem.Value} </div>
                        </div>
                    ))
            }
            </div>
        }
        else if (item.FilterCategory === 'VLT %') {
            let activeVltFilter = this.state.activeFilters.find(f => f.category === 'VLT %');
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color slider-item' : 'filter-item-container-color slider-item'}>
                <Slider value={[(+activeVltFilter?.values[0]?.code || 0), (+activeVltFilter?.values[1]?.code || 100)]} minimum={0} maximum={100} step={0.01} showTooltip onChange={(event) => this.changeSliderHandler(item.FilterCategory, event)}></Slider>
                <div className="slider-minmax">
                    <span>0</span>
                    <span>100</span>
                </div>
            </div>
        }
        else {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container' : 'filter-item-container'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="filter-item" key={filterItem.ID}>
                            <label className="filter-checkbox-label">
                                <input type="checkbox" value={filterItem.ID} checked={this.checkedFilter(item, filterItem.ID)}  onChange={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)} name="produktart" className="filter-checkbox" />
                                <span className="btn-checkbox-style">&nbsp;</span>
                                <span className="checkbox-label">{filterItem.Value} </span>

                            </label>
                        </div>
                    ))
            }
            </div>
        }

    }

    isHiddenFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            return true;
        }
        else {
            return false;
        }
    }

    handleHideFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            hiddenFilters.splice(hiddenFilterIndex, 1);
        }
        else {
            hiddenFilters.push(item.FilterCategory);
        }

        this.setState(() => ({
            hiddenFilters: hiddenFilters
        }))
    }

    cartButtonHandler = (event, product) => {

        //ANALYTICS
        /*ReactGA.plugin.require('ec', { debug: true });

        ReactGA.plugin.execute('ec', 'addProduct', {
            id: product.ID
        });
        ReactGA.plugin.execute('ec', 'setAction', 'add');

        ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart',{
            hitCallback: () => {
                event.stopPropagation();
                let cartProduct = Object.assign({Quantity: product.OrderMinQty}, product);
                this.setState({
                    ...this.state,
                    cartProduct: cartProduct,
                    openModalQuantity: true
                })
            }
        });  */
        event.stopPropagation();
        let cartProduct = Object.assign({Quantity: product.OrderMinQty}, product);
        this.setState({
            ...this.state,
            cartProduct: cartProduct,
            openModalQuantity: true
        });
    }

    toggleProductModal = () => {
        this.setState({
            ...this.state,
            cartProduct: null,
            openModalQuantity: !this.state.openModalQuantity
        });
    }

    changeProductQuantityHandler = event => {
        let product = Object.assign({}, this.state.cartProduct);
        product.Quantity = +event.target.value;
        this.setState({
            ...this.state,
            cartProduct: product
        });
    }

    addToCartHandler = () => {
        try {
            this.props.onAddToCart(this.state.cartProduct, this.props.authUser.zeissId);
            notify.success({ content: "Product has been added to your shopping cart.", options: { position: 'top-right', autoClose: 3000 } });
            this.toggleProductModal();
        } catch (e) {
            notify.error({ content: e, options: { position: 'top-right', autoClose: 3000 } });
        }
    }

    backToTop = () => {
        window.scrollTo(0, 0);

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.setState({ isScrolling: false });
        }, 300);

    }

    renderSkeletonFilters() {
        return <div className="skeleton-catalogue">
            <div className="skeleton-filters">
                <div className="filter-block">
                    <p className="filter-title"> Lens Color </p>
                    <div className="sk-filter-cat-color">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                    </div>
                </div>
                <div className="filter-block">
                    <p className="filter-title"> Color Type </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                </div>
                <div className="filter-block">
                    <p className="filter-title"> Filter Category </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                </div>
            </div>

        </div>
    }

    renderSkeletonItems() {
        return <div className="skeleton-items">
            <div className="products-container">
                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    }

    renderFilterMenu = displayClasses => {
        const classes = "filter-menu " + displayClasses;
        return (
            <div className={classes}>
                {
                    this.state.showFiltersItemSkeleton ?
                        this.renderSkeletonFilters()
                    :
                    <div>
                        {
                            this.state.filters.map((item) => (
                                    item.Values.length > 0 || item.FilterCategory === 'VLT %' ?
                                    <div className={item.FilterCategory === 'Collection' ? "filter-block filter-inverted" : "filter-block" }>
                                    <p className={this.isHiddenFilter(item) ? ' filter-title hidden-filter' : 'filter-title'} onClick={ () => { this.handleHideFilter(item) }}>
                                        {
                                            item.FilterCategory === 'Collection' ?
                                            <Icon name="Star" size={1} style={{margin: "0 0.4rem 0 0.2rem"}}/>
                                            : null
                                        }
                                        {item.FilterCategory}
                                    </p>
                                    {
                                        this.renderFilters(item)
                                    }
                                    </div>
                                    :
                                    null
                            ))
                        }
                    </div>
                }
            </div>
        );
    }

    renderSidebar = () => (
        <Sidebar
            position="left"
            activator=
                {
                    <div class="d-block d-md-none filter-sidebar-activator">
                        Filters
                    </div>
                }
        >
            <PaddedContainer gutter="small">
                <div style={{marginTop: '15px'}}>
                    <div>
                        {this.renderActiveFilters()}
                    </div>
                    {this.renderFilterMenu()}
                </div>
            </PaddedContainer>
        </Sidebar>
    );

    renderProductCard = (subitem) => {
        const inCart = this.checkProductInCart(subitem);
        return (
            <ProductCard
                key={subitem.ID}
                product={subitem}
                clicked={() => this.productSelectedHandler(subitem)}
                cartClicked={(event) => this.cartButtonHandler(event, subitem)}
                inCart={inCart}
                type='EShop'/>
        );
    }

    checkProductInCart = (item) => {
        const cartProduct = this.props.storedCart.find(product => product.ID === item.ID);
        return cartProduct !== undefined;
    }


    renderActiveFilters() {
        return <div>
            {
                this.state.activeFilters.map((activeFilter) => (
                    activeFilter.sortSelection != 999 ?
                        activeFilter.category !== 'VLT %' ?
                            activeFilter.values.map((activeFilterItem) => (
                                <p className="selected-filter-block" onClick={ () => this.removeSelectedFilter(activeFilter, activeFilterItem)}>
                                    {activeFilter.category}: {activeFilterItem.code}
                                </p>
                            ))
                        :
                            <p key={"active_filter"+activeFilter.category} className="selected-filter-block" onClick={ () => this.removeSelectedSliderFilter(activeFilter)}>
                                {activeFilter.category}:
                                {
                                    activeFilter.values.map((activeFilterItem, index) => (
                                        index === 0 ?
                                            " from " + activeFilterItem.code
                                        :
                                            " to " + activeFilterItem.code
                                    ))
                                }
                            </p>
                    :
                    null
                ))

            }
            </div>
    }

    handleClickSeeMore(section) {
        let seeMoreState = this.state.seeMoreState.find(x => x.id === section.ID);

        let addingValue = this.isDivisbleBy3(seeMoreState.count) ? 7 : 8;
        let count = seeMoreState.count + 8;
        this.updateSeeMoreItem(section.ID, {count: count});

    }

    isDivisbleBy3(number){
        if(number % 3 == 0) {
            return true;
        }
        else{
            return false;
        }
    }


    updateSeeMoreItem(id, itemAttributes) {
        var index = this.state.seeMoreState.findIndex(x=> x.id === id);
        if (index === -1) {

        }
        else
          this.setState({
            seeMoreState: [
               ...this.state.seeMoreState.slice(0,index),
               Object.assign({}, this.state.seeMoreState[index], itemAttributes),
               ...this.state.seeMoreState.slice(index+1)
            ]
          });
    }

    removeSelectedFilter (activeFilter, activeFilterItem) {
        this.handleChangeFilter(activeFilter.category,activeFilterItem.value, activeFilterItem.code);
    }

    removeSelectedSliderFilter (activeFilter) {
        let fCategory = this.state.activeFilters.find(e => e.category === activeFilter.category);
        if(fCategory) {
            fCategory.sortSelection = 999;
            fCategory.values = [];
        }
        this.handleChangeFilter(activeFilter.category, null, null);
    }

    renderEShopProductsCard (section) {

        let items = section.Items;
        let seeMoreState = this.state.seeMoreState.find(x => x.id === section.ID);

        let count = 8;
        let showSeeMoreButton = false;
        if(seeMoreState){
            count = seeMoreState.count;
            items = section.Items.slice(0, count);
        }

        if(section.Items.length > count) {
            showSeeMoreButton = true;
        }


        return <div className="products-container">
            {
                items.map((subitem) => (
                    this.renderProductCard(subitem)
                ))
            }
            {
                showSeeMoreButton ?
                <div className="see-more-container">
                    <Button className="see-more-button" buttonStyle="primary" style={{width: '120px' }}  onClick={ () => { this.handleClickSeeMore(section) }} >See more </Button>
                </div>
                :
                null
            }

        </div>
    }

    handleCloseShopLocked() {
        this.setState({openShopClosedModal: false});
        this.props.history.push("/home");
    }

    render () {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div className="eshop-container">
                    <div className="catalogue-container">
                        {
                            this.state.isScrolling ?
                            <div className="arrow-up" onClick={ () => this.backToTop()}>
                                <Icon name='KeyboardArrowUp' style={{color: '#fff'}}  size={3} />
                            </div>
                            :
                            null
                        }
                        {this.renderFilterMenu('d-none d-md-flex')}
                        {   this.state.showHomePage ?
                            <div className="catalogue-content">
                                {this.renderSidebar()}
                                {
                                    this.state.showFiltersItemSkeleton ?
                                    this.renderSkeletonItems()
                                    :
                                    <div style={{marginBottom: '30px'}}>
                                        { this.state.sections.map(section =>  {

                                            if(section.Items.length > 0 ) {
                                                return <div key={section.ID} className="catalogue-right-container">
                                                    <h3> {section.Title} </h3>
                                                    {
                                                        this.renderEShopProductsCard(section)
                                                    }
                                                </div>
                                            }

                                            })
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <div className="catalogue-content">
                                {this.renderSidebar()}
                                <div className="d-none d-md-block m-t-25">
                                    <div className="d-flex">
                                        {this.renderActiveFilters()}
                                    </div>
                                </div>
                                <div className="catalogue-right-container">
                                    {
                                        this.state.showFiltersItemSkeleton ?
                                            this.renderSkeletonItems()
                                        :
                                        <div>
                                           {
                                                this.state.items.map((subitem) => {
                                                    if(subitem.Items.length > 0 ) {
                                                        return <div key={subitem.Title} className="catalogue-right-container">
                                                            <h3> {subitem.Title} </h3>
                                                            <div className="products-container">
                                                                {
                                                                    subitem.Items.map((product) => (
                                                                        this.renderProductCard(product)
                                                                    ))
                                                                }

                                                            </div>
                                                        </div>
                                                    } else {
                                                        return <p>No products found with this filters.</p>
                                                    }
                                                })
                                           }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ModalProductQuantity
                    open={this.state.openModalQuantity}
                    toggle={this.toggleProductModal}
                    product={this.state.cartProduct}
                    changeQty={(event) => this.changeProductQuantityHandler(event)}
                    cartLength={this.props.storedCart.length}
                    addToCart={this.addToCartHandler}
                    icon={cartIcon}
                />
                {/* ecommerce suspended */}
                <Modal open={this.state.openShopClosedModal} onClose={this.handleCloseShopLocked.bind(this)}>
                    <ModalHeader title="E-shop is temporarily suspended"/>
                    <ModalBody>
                        The e-shop service is temporarily suspended. It will be available again on <b> {this.state.shopClosedEndDate} </b>.
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {

    let user;
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));
    }

    return {
        storedCart: state.eshop.cart,
        authUser: user,
        onlyPromoShop: checkAccountVisibilites(user),
        isPageVisible: checkPageVisibilites(user)
    };

    function checkAccountVisibilites(user) {
        let modules = user.modulesEnabled.split(';');
        if(modules.includes("E-commerce_Full")) {
            return false;
        }
        return true;
    }

    function checkPageVisibilites(user) {
        if(user) {
            if(user.modulesEnabled == null) {
                return false;
            }
            else {
                let modules = user.modulesEnabled.split(';');
                if(modules.includes("E-commerce_Full")) {
                    return true;
                }
                else if(modules.includes("E-commerce_Promo")) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else
            return false;
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAddToCart: (product, idzeiss) => dispatch(actionCreators.addToCart(product, idzeiss)),
        updateCart: (items) => dispatch(actionCreators.updateCart(items))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EShop));