import React, { Component } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import "./ContactUs.css"
import { Button, notify } from "precise-ui/dist/es6"
import axios from "../../../axios"
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../../config"

class ContactUs extends Component {
	state = {
		contactForm: {
			request: null,
			firstname: null,
			lastname: null,
			email: null,
			country: null,
		},
		errors: {
			request: null,
			firstname: null,
			lastname: null,
			email: null,
			country: null,
		},
        recaptchaValue: null
	}

	componentDidMount() {
		if (this.props.user) {
			this.loadAuthUserData()
		}
		window.scrollTo(0,0);

		if((typeof window.gtag !== 'undefined'))
        {
			window.gtag('config', 'AW-10905270588');
            window.gtag('event', 'conversion', {'send_to': 'AW-10905270588/ej_5CN-Qo5UYELzyhNAo'});
        }
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.user !== this.props.user && this.props.user) {
			this.loadAuthUserData()
		}
	}

	loadAuthUserData = (callback) => {
		this.setState({
			...this.state,
			contactForm: {
				...this.state.contactForm,
				firstname: this.props.user.name,
				lastname: this.props.user.surname,
				email: this.props.user.email,
				country: this.props.user.country,
			},
		}, callback)
	}

	inputChangeHadler = (event) => {
		const target = event.target
		const { name, value } = target
		this.setState(
			{
				...this.state,
				contactForm: {
					...this.state.contactForm,
					[name]: value,
				},
			},
			() => {
				const validation = this.fieldValidationHandler(target)
				this.updateFormErrors(name, validation)
			}
		)
	}

	fieldValidationHandler = (element) => {
		const { validity } = element
		let validationResult = null
		if (!validity.valid) {
			const errorMessage = this.getFieldValidationMessage(validity)
			validationResult = errorMessage
		}
		return validationResult
	}

	updateFormErrors = (name, validation) => {
		this.setState({
			...this.state,
			errors: {
				...this.state.errors,
				[name]: validation,
			},
		})
	}

	getFieldValidationMessage = (validity) => {
		if (validity.valueMissing) return "Field required"
		else return null // implement other errors in another time.
	}

	sendRequest = async (event) => {
		event.preventDefault();
		const valid = this.validateForm(event.target);
		if (valid) {
			const token = this.state.recaptchaValue;
			if (!token) {
				notify.error({
					content: "Unable to verify that your not a robot. Retry.",
					options: { position: "top-right", autoClose: 3000 },
				});
			}

            if (window.wcs) {
                window._nasa["cnv"] = window.wcs.cnv("4","1");
                window.wcs_do(window._nasa);
            }

			let data = {
				form: this.state.contactForm,
				user: this.props.user,
				recaptchaToken: token
			}
			axios
				.post("/sendContactUsEmail", JSON.stringify(data))
				.then((response) => {
					if (response.data == 1) {
						notify.success({
							content: "Email sent successfully",
							options: { position: "top-right", autoClose: 3000 },
						});

						this.setState({
                            ...this.state,
                            contactForm: {
                                ...this.state.contactForm,
                                request: "",
                                firstname: "",
                                lastname: "",
                                email: "",
                                country: "",
                            }
                        });
					} else {
						notify.error({
							content: "There was an error handling your request. Please try later.",
							options: { position: "top-right", autoClose: 3000 },
						})
					}
				})
				.catch((error) => {
					notify.error({
						content: "There was an error handling your request. Please try later.",
						options: { position: "top-right", autoClose: 3000 },
					})
				})
		}
	}

	validateForm = (form) => {
		const elements = [...form.elements]
		const errors = { ...this.state.errors }
		let validity = true
		elements.forEach((el) => {
			const elValidity = this.fieldValidationHandler(el)
			errors[el.name] = elValidity
			if (elValidity && validity) validity = false
		})
		this.setState({
			...this.state,
			errors: {
				...this.state.errors,
				...errors,
			},
		})
		return validity
	}

    onChangeRecaptcha = (value) => {
        this.setState({
            ...this.state,
            recaptchaValue: value
        });
    }


	render() {
		return (
			<div className="page-container">
				<div className="header-gray-line"> </div>
				<h1> Contact Us </h1>

				<div className="contact-us-container">
					<form
						name="contact-us-form"
						onSubmit={this.sendRequest}
						noValidate
					>
						<div className="contact-us-form-container">
							<div
								className={
									this.state.errors.request
										? "form-group form-error"
										: "form-group"
								}
							>
								<label className="lablel-co" for="request">
									Please specify your request:
								</label>
								<textarea
									className="other-text-area"
									value={this.state.contactForm.request}
									name="request"
									onChange={this.inputChangeHadler}
									required
								></textarea>
								{this.state.errors.request ? (
									<p className="form-error-message">
										{this.state.errors.request}
									</p>
								) : null}
							</div>

							<div className="contact-details-container">
								<div className="fg-1 form-row">
									<div
										className={
											this.state.errors.firstname
												? "form-group form-error"
												: "form-group"
										}
									>
										<label
											className="lablel-co"
											for="firstname"
										>
											Firstname:
										</label>
										<input
											type="text"
											value={
												this.state.contactForm.firstname
											}
											name="firstname"
											onChange={this.inputChangeHadler}
											disabled={this.props.user}
										/>
										{this.state.errors.firstname ? (
											<p className="form-error-message">
												{this.state.errors.firstname}
											</p>
										) : null}
									</div>
									<div
										className={
											this.state.errors.lastname
												? "form-group form-error"
												: "form-group"
										}
									>
										<label
											className="lablel-co"
											for="lastname"
										>
											Lastname:
										</label>
										<input
											type="text"
											value={
												this.state.contactForm.lastname
											}
											name="lastname"
											onChange={this.inputChangeHadler}
											disabled={this.props.user}
										/>
										{this.state.errors.lastname ? (
											<p className="form-error-message">
												{this.state.errors.lastname}
											</p>
										) : null}
									</div>
								</div>

								<div className="fg-1 form-row">
									<div
										className={
											this.state.errors.email
												? "form-group form-error"
												: "form-group"
										}
									>
										<label
											className="lablel-co"
											for="email"
										>
											Email:
										</label>
										<input
											type="text"
											value={this.state.contactForm.email}
											name="email"
											onChange={this.inputChangeHadler}
											required
											disabled={this.props.user}
										/>
										{this.state.errors.email ? (
											<p className="form-error-message">
												{this.state.errors.email}
											</p>
										) : null}
									</div>
									<div
										className={
											this.state.errors.country
												? "form-group form-error"
												: "form-group"
										}
									>
										<label
											className="lablel-co"
											for="country"
										>
											Country:
										</label>
										<input
											type="text"
											value={
												this.state.contactForm.country
											}
											name="country"
											onChange={this.inputChangeHadler}
											disabled={this.props.user}
										/>
										{this.state.errors.country ? (
											<p className="form-error-message">
												{this.state.errors.country}
											</p>
										) : null}
									</div>
								</div>
							</div>
                            <ReCAPTCHA
                                sitekey={config.recaptchaKey}
                                onChange={this.onChangeRecaptcha}
                            />
							<div className="form-submit">
								<Button
									className="btn-form-submit"
									type="submit"
								>
									Send
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	}
}

export default withRouter(connect(mapStateToProps, null)(ContactUs))
