import React, { Component } from "react";
import { withRouter } from 'react-router';
import './ProductManual.css';
import {config} from '../../../config';

class ProductManual extends Component {

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <div className="page-container cd-container">
                <div className="header-gray-line"> </div>
                <h1> Product Manual </h1>
                <div className="cd-content">
                    <p>Please click here to download our <b>Product Manual: </b> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/CARL_ZEISS_VISION_SUNLENS_PRODUCT_MANUAL_PM_001_Rev_14_January_2024_English_signed.pdf"} target="_blank"> In English </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/CARL_ZEISS_VISION_SUNLENS-PRODUCT_MANUAL_PM_001_Rev_14_January_2024_Chinese.pdf"} target="_blank"> In Chinese </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/CARL_ZEISS_VISION_SUNLENS-MANUALE_PRODOTTO_PM_001_Rev_14_Gennaio_2024_Italiano.pdf"} target="_blank" >In Italian </a> </p>
                </div>
            </div>
        )
    }
}

export default withRouter(ProductManual);