import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'precise-ui/dist/es6';
import './ModalRequestInfo.css';

const modalRequestInfo = props => (
    <Modal open={props.open} onClose={props.close} >
        <ModalHeader title="Request SKU info" />
        <ModalBody>
            <p> Please specify your request: </p>
            <textarea className="modal-text-area" onChange={props.handleChangeInput} name="request"></textarea>
            <br/>
            <Table
                className="cart-table"
                data={props.products}
                columns={props.tableColumns}
                cellRenderer={props.cellTableRender}
                placeholder="No items in wishlist yet."
                borderless
            />
        </ModalBody>
        <ModalFooter className="m-t-3">
            <Button type="button" onClick={props.close}>Cancel</Button>
            <Button type="submit" onClick={props.sendRequest}> Send request </Button>
        </ModalFooter>
    </Modal>
);

export default modalRequestInfo;