import React, { Component } from "react";
import { withRouter } from 'react-router';
import './ChemicalProductCompliance.css';
import { config } from "../../../config";

class ChemicalProductCompliance extends Component {

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <div className="page-container cd-container">
                <div className="header-gray-line"> </div>
                <h1> Chemical and Product Regulatory Compliance </h1>
                <div className="cd-content">
                    <p>Please click here to download our <b>Reach declaration: </b> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/1b_CZVI-Sunlens-REACH-Declaration-Rev.-27-July-2024-Final-Signed.pdf"} target="_blank"> In English </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/2-_REACH-Declaration-Rev.-27-July-2024-Final-Signed-CN.pdf"} target="_blank" > In Chinese </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/2-_REACH-Declaration-Rev.-27-July-2024-Final-Signed-IT.pdf"} target="_blank" >In Italian </a> </p>
                    <br/>
                    <p>Please click here to download our <b>PFAS Statement: </b> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/Compliance-PFAS-EU-ZEISS-2024-detailed.pdf"} target="_blank"> In English </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/Conformita-PFAS-EU-ZEISS-2024-dettagli.pdf"} target="_blank"> In Italian </a> </p>
                    <br/>
                    <p>Please click here to download our <b>Cadmium and Lead Letter: </b> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/Cadmium-Lead-compliance-ZEISS-2024.pdf"} target="_blank"> In English </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/Conformita-cadmio-e-piombo-ZEISS-2024.pdf"} target="_blank"> In Italian </a> </p>
                </div>
            </div>
        );
    }
}

export default withRouter(ChemicalProductCompliance);