import React, {Component} from 'react';

import { Route, Redirect, Switch, withRouter, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';

import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga';

import Header from '../components/Header/Header';
import NavigationHeader from '../components/Header/NavigationHeader/NavigationHeader';
import Footer from '../components/Footer/Footer';
// Pages
import HomePage from './Pages/HomePage/HomePage'
import HomePageProspect from './Pages/HomePageProspect/HomePageProspect'
import Catalogue from './Pages/Catalogue/Catalogue'
import Callback from "../components/Auth/Callback";
import AboutUs from "./Pages/AboutUs/AboutUs";
import EShop from "./Pages/EShop/EShop";
import Stock from "./Pages/Stock/Stock";
import Cart from './Pages/EShop/Cart/Cart';
import Product from './Pages/Product/Product';
import ProductManual from './Pages/ProductManual/ProductManual';
import Configurator from './Pages/Configurator/Configurator';
import Coatings from './Pages/Coatings/Coatings'

// Integrations
import CallbackSignUp from '../components/Auth/CallbackSignUp';
import XPayOutCome from '../components/XPay/XPayOutCome';

import axios from '../axios';
import Wishlist from './Pages/Wishlist/Wishlist';
import * as actions from '../store/actions/index';
import CallBackLogout from '../components/Auth/CallBackLogout';
import PersonalArea from './Pages/PersonalArea/PersonalArea';
import MyOrders from './Pages/MyOrders/MyOrders';
import Profile from './Pages/PersonalArea/Profile';
import MyOrder from './Pages/MyOrder/MyOrder';
import ContactUs from './Pages/ContacUs/ContactUs';
import { Notifications } from 'precise-ui/dist/es6';
import CodeOfConduct from './Pages/CodeOfConduct/CodeOfConduct';
import ModalFullPrivilegeReminder from '../components/ModalFullPrivilegeReminder/ModalFullPrivilegeReminder';
import ModalFullPrivilege from '../components/ModalFullPrivilege/ModalFullPrivilege'
import { instanceOf } from 'prop-types';
import SearchResult from './Pages/Catalogue/SearchResult';
import Returns from './Pages/Returns/Returns';
import Cookies from 'universal-cookie';
import { config } from '../config';
import ELearning from './Pages/E-Learning/ELearning';
import VendorsNetwork from './Pages/VendorsNetwork/VendorsNetwork';
import VendorNetwork from './Pages/VendorNetwork/VendorNetwork';
import StandardSalesCondition from './Pages/StandardSalesCondition/StandardSalesCondition';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Pricelist from './Pages/Pricelist/Pricelist';
import InventoryCart from './Pages/Stock/InventoryCart/InventoryCart';
import MyStockRequests from './Pages/MyStockRequests/MyStockRequests';
import MyStockRequest from './Pages/MyStockRequest/MyStockRequest';
import ModalNewsletter from '../components/ModelNewsletter/ModalNewsletter';
import CookiePolicy from './Pages/CookiePolicy/CookiePolicy';
import Loader from 'react-loader-spinner';
import Packaging from './Pages/Packaging/Packaging';
import LeadTime from './Pages/LeadTime/LeadTime';
import MyDocuments from './Pages/MyDocuments/MyDocuments';
import ChemicalProductCompliance from './Pages/ChemicalProductCompliance/ChemicalProductCompliance';

const history = createHistory()

/*
ReactGA.initialize(config.analytics);
ReactGA.plugin.require('ec', { debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
});*/

/*Sentry.init({
  dsn: "https://5b7886b548a8478189c7ce8f7f3e7b39@o556131.ingest.sentry.io/5757096",
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 0.3,
});*/

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    menuItems : [
      { id: 'Home',
        title: 'Home',
        URL: '/home',
        component: HomePage,
        visibility: 'All',
        subPages: []
      }
    ],
    error: false,
    openModalFPReminder: false,
    openModalNewsletter: false ,
    privacyAccepted: false,
    hasCookieBot: false,
    isLoading: false
  }

  constructor() {
    super();
    this.insertGtmScript();
    this.insertNaverScript();
    this.insertGtagScript();
    this.toggleNaverContactUsScript();
  }

  componentDidMount () {
    this.insertCookiebotScript();

    axios.get( '/getMainMenu', {
        headers: {
            'Content-Type':  'application/json;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
    .then( async (response) => {

        const menuItems = response.data.map(menuItem => {
            return {
                id: menuItem.ID,
                title: menuItem.Title,
                URL: '/'+menuItem.URLSegment,
                visibility: menuItem.Visibility,
                subPages: menuItem.SubPages
            }
        }
    )
    this.setState({...this.state, menuItems: menuItems, hasCookieBot: !!document.querySelector('#CookieBot')}, this.callbackDidMount);


    } )
    .catch(error => {
        this.setState({...this.state, error: true, hasCookieBot: !!document.querySelector('#CookieBot')}, this.callbackDidMount);
    });


    let user = null;
    user = JSON.parse(localStorage.getItem('user'));
    if(user) {
        this.props.onAuth(user);
    }
    else {
        localStorage.removeItem('user');
        user = null;
        this.props.onAuth(user);
        let locationSearchParams = window.location.search;
        this.props.history.push("/home"+locationSearchParams);
    }

    this.insertLinkedinScript();
    this.insertHotjarScript();
    this.insertActiveCampaignScript(user?.email);

    this.unlisten = history.listen(() =>  {
        this.toggleNaverContactUsScript();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated != this.props.isAuthenticated && this.props.isAuthenticated) {
      this.callbackDidMount();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  callbackDidMount = () => {
    this.checkNewsletterSubscribtion();
  }

  beforeCloseCookiesModalHandler = accepted => {
    // prevent backdrop close
  }

  checkFullPrivilegeCookie = () => {
    const cookies = new Cookies();
    if (this.props.isAuthenticated && this.props.user && this.props.user.accountType != 'Account' && !this.props.user.fullPrivilegeRejected) {
      if(!cookies.get('fp-requested')) {
          let fullPrivilegeCookie = cookies.get('fp-reminder');
          if(fullPrivilegeCookie){
            let now = new Date();
            let cookieDate = new Date(fullPrivilegeCookie);
            if(now > cookieDate ){
              this.setState({...this.state, openModalFPReminder: true});
              cookies.remove('fp-reminder');
            }
          } else {
            this.closeFullPrivilegeModalHandler(); // set 3 days cookie for reminder
          }
        } else {
          cookies.remove('fp-reminder');
        }
    } else {
      cookies.remove('fp-reminder');
      cookies.remove('fp-requested');
    }
  }

  closeFullPrivilegeModalHandler = () => {
    const cookies = new Cookies();
    let dateIn3Days = new Date();
    dateIn3Days.setDate(dateIn3Days.getDate() + 3);
    cookies.set('fp-reminder', dateIn3Days, { path: '/'});
    this.setState({...this.state, openModalFPReminder: false});
  }

  openModalFullPrivilege = () => {
    this.closeFullPrivilegeModalHandler();
    this.props.toggleFullPrivilegeModal();
  }

  checkNewsletterSubscribtion = () => {
    const cookies = new Cookies();
    const newsletterAlreadySeen = cookies.get('newsletter_seen');
    if (this.props.isAuthenticated && !this.props.user.privacy && !newsletterAlreadySeen) {
      this.setState({...this.state, openModalNewsletter: true});
    }
  }

  closeNewsletterModalHandler = (accepted) => {
    const cookies = new Cookies();
    let d = new Date();
    d.setMonth(d.getMonth() + 3);
    cookies.set('newsletter_seen', true, { path: '/', expires: d });

    if (!this.state.privacyAccepted && this.props.user && this.props.user.privacy != accepted) {
      let params = {
        environment: config.salesForceENV,
        zeissid:  this.props.user.zeissId,
        email: this.props.user.email,
        lastname:  this.props.user.surname,
        privacy: accepted
      }

      this.setState({...this.state, isLoading: true, openModalNewsletter: false});

      axios.post('/salesforceUpdate', JSON.stringify(params),
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      ).then(response => {
        this.setState({...this.state, privacyAccepted: true, isLoading: false});
        let userStored = localStorage.getItem('user');
        if (userStored) {
          let user = JSON.parse(userStored);
          user.privacy = accepted;
          localStorage.setItem('user', JSON.stringify(user));
          this.props.onChangePrivacy(accepted);
        }
      }).catch(err => {
        console.log(err);
        this.setState({...this.state, isLoading: false, openModalNewsletter: true});
      });
    } else {
      this.setState({...this.state, openModalNewsletter: false});
    }
  }

  insertLinkedinScript = () => {
    const firstScript = document.createElement("script");
    firstScript.type = "text/javascript";
    firstScript.innerHTML = `
      _linkedin_partner_id = "4034836";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;

    const secondScript = document.createElement("script");
    secondScript.type = "text/javascript";
    secondScript.innerHTML = `
      (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);
    `;

    document.body.appendChild(firstScript);
    document.body.appendChild(secondScript);
  }

  insertHotjarScript = () => {
    const script = document.createElement("script");
    script.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2961342,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.body.appendChild(script);
  }

  insertCookiebotScript = () => {
    if (!this.state.hasCookieBot) {
      const script = document.createElement('script');
      script.setAttribute('id', 'CookieBot');
      script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
      script.setAttribute('data-cbid', config.cookiebotDomainGroupId);
      script.setAttribute('data-blockingmode', 'auto');
      script.setAttribute('type', 'text/javascript');
      const head = document.querySelector('html > head');
      head.insertBefore(script, head.firstChild);
    }
  }

  insertActiveCampaignScript = (userEmail) => {
    const head = document.querySelector('html > head');

    const script = document.createElement("script");
    script.innerHTML = `
      (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
      vgo('setAccount', '${config.activeCampaignAccount}');
      vgo('setTrackByDefault', true);
      ${!!userEmail ? `vgo('setEmail', '${userEmail}')`: ''}
      vgo('process');
    `;
    head.insertBefore(script, head.firstChild);
  }

  insertGtmScript = () => {

    const existGtmScript = document.getElementById('GTMScript') !== null;

    if (!existGtmScript) {
        const head = document.querySelector('html > head');
        const script = document.createElement('script');
        script.setAttribute('id', 'GTMScript');
        script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.gtm}');
        `;
        head.insertBefore(script, head.firstChild);

        const noScript = document.createElement('noscript');
        noScript.innerHTML = `
            <iframe src=https://www.googletagmanager.com/ns.html?id=${config.gtm} height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `;
        document.body.firstChild.after(noScript);
    }
  }

  insertGtagScript = () => {
    const head = document.querySelector('html > head');

    const script = document.createElement('script');
    script.setAttribute('id', 'GTAGScript');
    script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${config.gtag}`);

    const scriptDataLayer = document.createElement('script');
    scriptDataLayer.innerHTML = `
      window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date());
    `;
    head.insertBefore(script, head.firstChild);
    document.getElementById('GTAGScript').after(scriptDataLayer);
  }

  insertNaverScript = () => {
    const head = document.querySelector('html > head');

    const scriptNaverTrack = document.createElement('script');
    scriptNaverTrack.setAttribute("id", "naver-base");
    scriptNaverTrack.innerHTML = `
        if (!wcs_add) var wcs_add={};
        wcs_add["wa"] = "s_116f6b0d2cd4";
        if (!_nasa) var _nasa={};
        if(window.wcs){
            wcs.inflow();
            wcs_do(_nasa);
        }
    `;
    head.insertBefore(scriptNaverTrack, head.firstChild);
  }

  toggleNaverContactUsScript() {
    const head = document.querySelector('html > head');
    const naverLogScriptId = 'naver-log';
    const naverContactUsScriptId = 'naver-contact-us';
    const existScript = !!document.getElementById(naverContactUsScriptId);
    if (window.location.pathname.toLowerCase() === '/contact-us' && !existScript) {
        const script = document.createElement('script');
        script.setAttribute("id", naverLogScriptId);
        script.setAttribute('src', `//wcs.naver.net/wcslog.js`);

        const scriptContactUs = document.createElement('script');
        scriptContactUs.setAttribute("id", naverContactUsScriptId);
        scriptContactUs.innerHTML = `
            if (!wcs_add) var wcs_add={};
            wcs_add["wa"] = "s_116f6b0d2cd4";
            var _nasa={};
            if (window.wcs) {
                _nasa["cnv"] = wcs.cnv("4","1");
                wcs_do(_nasa);
            }
        `;

        head.insertBefore(scriptContactUs, head.firstChild);
        head.insertBefore(script, head.firstChild);
    } else if (existScript) {
        const scriptContactUs = document.getElementById(naverContactUsScriptId);
        if (scriptContactUs) {
            scriptContactUs.remove()
        }
        const scriptLog = document.getElementById(naverLogScriptId);
        if (scriptLog) {
            scriptLog.remove()
        }
    }
  }

  render () {

   let routes = (
    <Switch>
      <Route path="/home" component={HomePage} />
      <Route path="/about-us" exact component={AboutUs} />
      <Route path="/signin-oidc" component={Callback} />
      <Route path="/signup-oidc" component={CallbackSignUp} />
      <Route path="/logout" component={CallBackLogout} />
      <Route path="/xpay-outcome" component={XPayOutCome} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/code-of-conduct" component={CodeOfConduct} />
      <Route path="/standard-sales-conditions" component={StandardSalesCondition} />
      <Route path="/cookie-policy" component={CookiePolicy}/>
      <Route path="/packaging" component={Packaging}/>
      <Redirect to="/home"/>
    </Switch>
  );

  if ( this.props.isAuthenticated ) {
    routes = (
      <Switch>
        <Route path="/home-prospect" component={HomePageProspect} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/catalogue/:materialId?/:collectionId?" exact render={(props) => <Catalogue {...props} onMountCheckFPCookie={this.checkFullPrivilegeCookie} />} />
        <Route path="/search-result" component={SearchResult}/>
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/signup-oidc" component={CallbackSignUp} />
        <Route path="/e-shop/products" exact component={EShop} />
        <Route path="/e-shop/returns" exact component={Returns} />
        <Route path="/product/:id" component={Product} />
        <Route path="/cart" exact component={Cart} />
        <Route path="/xpay-outcome" component={XPayOutCome} />
        <Route path="/wishlist" component={Wishlist} />
        <Route path="/logout" component={CallBackLogout} />
        <Route path="/my-personal-area" component={PersonalArea} />
        <Route path="/my-orders" component={MyOrders} />
        <Route path="/my-order/:id" component={MyOrder} />
        <Route path="/profile" component={Profile} />
        <Route path="/code-of-conduct" component={CodeOfConduct} />
        <Route path="/e-learning/:intLevel?/:level?" render={() => <ELearning onMountCheckFPCookie={this.checkFullPrivilegeCookie} />} />
        <Route path="/leads-e-learning/:intLevel?/:level?" render={() => <ELearning onMountCheckFPCookie={this.checkFullPrivilegeCookie} />} />
        <Route path="/product-manual" component={ProductManual} />
        <Route path="/chemical-product-regulatory-compliance" component={ChemicalProductCompliance} />
        <Route path="/vendors-network/:id" component={VendorNetwork} />
        <Route path="/vendors-network" exact component={VendorsNetwork} />
        <Route path="/standard-sales-conditions" component={StandardSalesCondition} />
        <Route path="/pricelist" component={Pricelist} />
        <Route path="/stock" exact component={Stock} />
        <Route path="/my-stock-request/:id" exact component={MyStockRequest} />
        <Route path="/my-stock-requests" exact component={MyStockRequests} />
        <Route path="/inventory-cart" exact component={InventoryCart} />
        <Route path="/configurator" exact component={Configurator} />
        <Route path="/cookie-policy" component={CookiePolicy}/>
        <Route path="/packaging" component={Packaging}/>
        <Route path="/leadtime" component={LeadTime}/>
        <Route path="/coatings/:materialId?" exact component={Coatings}/>
        <Route path="/my-documents" component={MyDocuments} />
        <Redirect to="/home-prospect" />
      </Switch>
    );
  }

    return (
        <Router history={history}>
            <Header menuItems={this.state.menuItems}></Header>
            <NavigationHeader menuItems={this.state.menuItems} ></NavigationHeader>
            <div className="route-container">
                {routes}
            </div>
            <ModalFullPrivilegeReminder open={this.state.openModalFPReminder} close={this.closeFullPrivilegeModalHandler}  openModalFullPrivilege={this.openModalFullPrivilege} />
            <ModalFullPrivilege></ModalFullPrivilege>
            <ModalNewsletter open={this.state.openModalNewsletter} close={this.closeNewsletterModalHandler} accepted={this.state.privacyAccepted}></ModalNewsletter>
            <Notifications />
            {
            this.state.isLoading ?
            <Loader
              type="TailSpin"
              color="#008bd0"
              height={100}
              width={100}
              timeout={1500000}
              className="spinner"
            />
            :
            null
            }
            {
            this.state.isLoading ?
            <div className="black-layer">
            </div>
            :
            null
            }
            <Footer onClick={this.handleCookieClick}></Footer>
        </Router>
    );
  }

}

const mapStateToProps = state => {
  let user;
  if(state.auth.user) {
      user = state.auth.user;
  }
  else {
    user = JSON.parse(localStorage.getItem('user'));
  }

  return {
      isAuthenticated: user ? true : false,
      user : user
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onAuth: (user) => dispatch( actions.authSuccess(user) ),
      onChangePrivacy: (privacy) => dispatch(actions.authChangePrivacy(privacy)),
      toggleFullPrivilegeModal: () => dispatch(actions.toggleFullPrivilegeModal())
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));